import React, { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../../context/editor/ModalContext'
import { FormNpcs } from './FormNpcs'
import { API_MANAGE_NPC } from '../../../../../utils/constantsAdmin'
import { SearchCamp } from '../../../searchAndPagination/SearchCamp'
import { NpcsCollapse } from './NpcsCollapse'
import { Pagination } from '../../../searchAndPagination/Pagination'
import { LoadingIcon } from '../../../LoadingIcon'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ListNpcs = () => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	//REFRESH NPCS
	const [refreshNpcs, setRefreshNpcs] = useState(false)

	// DATA
	const [data, setData] = useState(undefined)

	// GET ALL ANIMATIONS
	useEffect(() => {
		getData()
	}, [refreshNpcs])

	const getData = async () => {
		try {
			const result_data = await axiosSupreme('get', API_MANAGE_NPC)
			setData(result_data)
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddNpcsModal = () => {
		setTitleModal(t('pages.editor.components.list_npcs.add_npcs'))
		setContentModal(<FormNpcs setRefreshNpcs={setRefreshNpcs} />)
		openModal(true)
	}

	// ACTIVECOLLAPSE
	const [activeCollapseNpc, setActiveCollapseNpc] = useState(undefined)

	return (
		<>
			<h1 className='npcList__titleNPCS'>
				{t('pages.editor.components.list_npcs.data_and_aparience_npc')}
			</h1>
			<div className='npcList__btnAdd'>
				<button
					className='editorBtnActionAdd'
					onClick={OpenAddNpcsModal}>
					{t('common.add')}
				</button>
			</div>

			{data !== undefined ? (
				<>
					{data === 'search' || data.results.length > 0 ? (
						<>
							<SearchCamp
								setData={setData}
								setRefreshDataList={setRefreshNpcs}
								url={API_MANAGE_NPC}
								search={search}
								setSearch={setSearch}
							/>
							{data === 'search' ? (
								<p className='noSelect'>
									{t(
										'pages.editor.components.list_npcs.none_npc_found'
									)}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							{t(
								'pages.editor.components.list_npcs.none_npc_found'
							)}
						</p>
					)}
					{data !== 'search' ? (
						<div className='npcList__containerList'>
							{data.results.map((data_item, index) => (
								<NpcsCollapse
									key={data_item.id_npc}
									objectNpc={data_item}
									setRefreshNpcs={setRefreshNpcs}
									activeCollapseNpc={activeCollapseNpc}
									setActiveCollapseNpc={setActiveCollapseNpc}
								/>
							))}
							<Pagination
								data={data}
								setData={setData}></Pagination>
						</div>
					) : null}
				</>
			) : (
				<LoadingIcon />
			)}
		</>
	)
}
