import { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../context/editor/ModalContext'
import { FormDialogues } from './FormDialogues'
import { API_MANAGE_DIALOGUES_BY_NPC_COMPOSE } from '../../../../utils/constantsAdmin'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import { DialogueCollapse } from './DialogueCollapse'
import { Pagination } from '../../searchAndPagination/Pagination'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { LoadingIcon } from '../../LoadingIcon'
import { useTranslation } from 'react-i18next'

export const ListDialogues = ({ itemCompose, setStatusGreeting }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// REFRESH DIALOGUES
	const [refreshDialogues, setRefreshDialogues] = useState(false)

	// GET ALL DIALOGUES
	const [data, setData] = useState(undefined)
	useEffect(() => {
		const getDialogues = async () => {
			const result_data = await axiosSupreme(
				'get',
				API_MANAGE_DIALOGUES_BY_NPC_COMPOSE +
					itemCompose.id_npc_compose_sub_world_scene_npc_appearance +
					'/',
				undefined
			)
			setData(result_data)
			if (result_data.results.length > 0) {
				setStatusGreeting(true)
			} else {
				setStatusGreeting(false)
			}
		}
		getDialogues()
	}, [refreshDialogues])

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddDialoguesModal = () => {
		setTitleModal(t('pages.editor.components.list_dialogues.title'))
		setContentModal(
			<FormDialogues
				id_npc_compose_sub_world_scene_npc_appearance={
					itemCompose.id_npc_compose_sub_world_scene_npc_appearance
				}
				setRefreshDialogues={setRefreshDialogues}
			/>
		)
		openModal(true)
	}

	// ACTIVECOLLAPSE
	const [activeCollapseDialogue, setActiveCollapseDialogue] =
		useState(undefined)

	return (
		<div className='npcSceneContent__container__components'>
			<hr />
			<div className='npcSceneContent__container__components__btnAdd'>
				<h1 className='error'> Dialogos (Saludos) </h1>
				<div className='boxBtnAdd'>
					<button
						className='editorBtnActionAdd'
						onClick={OpenAddDialoguesModal}>
						{t('common.add')}
					</button>
				</div>
			</div>
			{data !== undefined ? (
				<>
					{data === 'search' || data.results.length > 0 ? (
						<>
							<SearchCamp
								setData={setData}
								setRefreshDataList={setRefreshDialogues}
								url={
									API_MANAGE_DIALOGUES_BY_NPC_COMPOSE +
									itemCompose.id_npc_compose_sub_world_scene_npc_appearance +
									'/'
								}
								search={search}
								setSearch={setSearch}
							/>
							{data === 'search' ? (
								<p className='noSelect'>
									{t(
										'pages.editor.components.list_dialogues.none_dialogue'
									)}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							{t(
								'pages.editor.components.list_dialogues.no_dialogue_registered'
							)}
						</p>
					)}
					{data !== 'search' ? (
						<div className='npcSceneContent__container__components__content'>
							{data.results.map((data_item, index) => (
								<DialogueCollapse
									key={data_item.id_dialogue}
									id_npc_compose_sub_world_scene_npc_appearance={
										itemCompose.id_npc_compose_sub_world_scene_npc_appearance
									}
									objectDialogue={data_item}
									setRefreshDialogues={setRefreshDialogues}
									activeCollapseDialogue={
										activeCollapseDialogue
									}
									setActiveCollapseDialogue={
										setActiveCollapseDialogue
									}
								/>
							))}
							<Pagination
								data={data}
								setData={setData}></Pagination>
						</div>
					) : null}
				</>
			) : (
				<LoadingIcon />
			)}
		</div>
	)
}
