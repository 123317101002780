import { useContext, useEffect } from 'react'
import ModalContext from '../../context/editor/ModalContext'
import '../../styles/Modal.scss'

export default function Modal() {
	const { isOpen, openModal, titleModal, contentModal } =
		useContext(ModalContext)

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === 'Escape' && isOpen) {
				openModal(false)
			}
		}
		document.addEventListener('keydown', handleKeyDown)
		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [isOpen, openModal])

	return (
		<>
			<div
				id='myModal'
				className='modal'
				style={{ display: isOpen === true ? 'block' : 'none' }}>
				<div className='modal-content'>
					<span className='close' onClick={openModal}>
						&times;
					</span>
					<h2>{titleModal}</h2>
					{contentModal}
				</div>
			</div>
		</>
	)
}
