import { useContext, useEffect, useRef, useState } from 'react'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import {
	API_MANAGE_ANIMATIONS,
	API_MANAGE_SOUNDS,
} from '../../../../utils/constantsAdmin'
import SaveButton from '../../SaveButton'
import {
	handleKeyPressInteger,
	onInputChangeDefault,
	onInputChangeImage,
	processErrors,
} from '../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'
import { useFetchOptions } from '../../../../hooks/useFetchOptions/useFetchOptions'

export function FormAnimations({
	setRefreshAnimations,
	stateObjectAnimation,
	setStateObjectAnimation,
}) {
	const { t } = useTranslation()
	// AXIOS
	const { axiosSupreme } = useAuthProvider()

	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [name, setName] = useState('')
	const imageFile = useRef(null)
	const [frameSizeX, setFrameSizeX] = useState('')
	const [frameSizeY, setFrameSizeY] = useState('')
	const [numbFrames, setNumbFrames] = useState('')
	const [msDuration, setMsDuration] = useState('')
	const [sound, setSound] = useState('')

	// LIST VALUES
	const [listSounds, setListSounds] = useState({})

	const { fetchOptions } = useFetchOptions()

	useEffect(() => {
		const updateDataList = (dataList) => {
			setListSounds((prevData) => ({
				...prevData,
				...dataList.reduce((acc, data_item) => {
					acc[data_item.id_sound] = { name: data_item.name }
					return acc
				}, {}),
			}))
		}

		const abortController = new AbortController()
		fetchOptions(API_MANAGE_SOUNDS, updateDataList, abortController.signal)

		return () => {
			abortController.abort()
		}
	}, [])

	// ERROR FOR FORM
	const [errorImage, setErrorImage] = useState('')

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		imageFile.current.value = null
		setFrameSizeX('')
		setFrameSizeY('')
		setNumbFrames('')
		setMsDuration('')
	}

	// SET ANIMATION WHEN UPDATE
	useEffect(() => {
		if (stateObjectAnimation !== undefined) {
			setName(stateObjectAnimation.name)
			setNumbFrames(stateObjectAnimation.frames_amount)
			setMsDuration(stateObjectAnimation.frames_duration)
			if (stateObjectAnimation.sound) {
				setSound(stateObjectAnimation.sound.id_sound)
			}
			if (stateObjectAnimation.frame_size) {
				setFrameSizeX(
					stateObjectAnimation.frame_size
						.replace('[', '')
						.replace(']', '')
						.split(',')[0]
				)
				setFrameSizeY(
					stateObjectAnimation.frame_size
						.replace('[', '')
						.replace(']', '')
						.split(',')[1]
				)
			}
		} else {
			clearFields()
		}
	}, [stateObjectAnimation])

	// SUBMIT FORM ANIMATION
	const onSubmitAnimation = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('name', event.target.elements.name.value)
		formData.append('sound', sound ? sound : '')
		if (event.target.elements.image_file.value) {
			formData.append(
				'image_file',
				event.target.elements.image_file.files[0]
			)
		}
		if (
			event.target.elements.frameSizeX.value &&
			event.target.elements.frameSizeY.value
		) {
			formData.append(
				'frame_size',
				'[' + frameSizeX + ',' + frameSizeY + ']'
			)
		}
		formData.append(
			'frames_amount',
			event.target.elements.frames_amount.value
		)
		formData.append(
			'frames_duration',
			event.target.elements.frames_duration.value
		)

		if (stateObjectAnimation === undefined) {
			// SAVE
			manageAnimation(event, API_MANAGE_ANIMATIONS, formData, 'add')
		} else {
			// UPDATE
			manageAnimation(
				event,
				API_MANAGE_ANIMATIONS + stateObjectAnimation.id_animation + '/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE LEVEL
	const manageAnimation = async (event, url, obj, action) => {
		try {
			await axiosSupreme(action === 'add' ? 'post' : 'patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			if (action === 'add') setRefreshAnimations((prev) => !prev)
			if (action === 'update') getNewObject()
			openModal(false)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	const getNewObject = async () => {
		let urlRequest = API_MANAGE_ANIMATIONS
		const result_data = await axiosSupreme(
			'get',
			(urlRequest += stateObjectAnimation.id_animation + '/'),
			undefined
		)
		setStateObjectAnimation(result_data)
	}

	const handleKeyPress = (event) => {
		const charCode = event.charCode || event.keyCode
		if ((charCode < 48 || charCode > 57) && charCode !== 46) {
			event.preventDefault()
		}
	}

	return (
		<>
			<form onSubmit={onSubmitAnimation}>
				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t('pages.editor.components.form_animations.name')}
					</label>
					<input
						maxLength='45'
						name='name'
						className='admin__container__inputs__in'
						id='name'
						type='text'
						placeholder={t(
							'pages.editor.components.form_animations.placeholder_name'
						)}
						autoComplete='off'
						value={name}
						onChange={(event) =>
							onInputChangeDefault(event, setName)
						}
						required></input>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t('pages.editor.components.form_animations.sprite')}
					</label>
					{stateObjectAnimation !== undefined ? (
						<>
							{stateObjectAnimation.image_file !== null ? (
								<label htmlFor=''>
									{t('common.current_file')}
									<a
										href={stateObjectAnimation.image_file}
										target='_blank'
										rel='noopener noreferrer'>
										{t('common.open')}
									</a>
								</label>
							) : null}
						</>
					) : null}
					<input
						name='image_file'
						className='admin__container__inputs__in'
						id='image_file'
						type='file'
						ref={imageFile}
						autoComplete='off'
						onChange={(event) =>
							onInputChangeImage(event, setErrorImage, imageFile)
						}
						accept='image/*'
						required={stateObjectAnimation === undefined}></input>
					{errorImage && <p>{errorImage}</p>}
				</div>

				<div
					className='admin__container__inputsCoordenadas'
					id='admin__container__inputsCoordenadas'>
					<label
						className='admin__container__inputsCoordenadas__title'
						forhtml='admin__container__inputsCoordenadas__title'>
						{t(
							'pages.editor.components.form_animations.frame_size'
						)}
					</label>
					<div className='admin__container__inputsCoordenadas__columns'>
						<input
							maxLength='45'
							name='frameSizeX'
							className='admin__container__inputsCoordenadas__columns__in'
							id='frameSizeX'
							type='text'
							placeholder={t(
								'pages.editor.components.form_animations.placeholder_widht'
							)}
							autoComplete='off'
							value={frameSizeX}
							onChange={(event) =>
								onInputChangeDefault(event, setFrameSizeX)
							}
							onKeyPress={(event) => handleKeyPress(event)}
							required></input>
						<input
							maxLength='45'
							name='frameSizeY'
							className='admin__container__inputsCoordenadas__columns__in'
							id='frameSizeY'
							type='text'
							placeholder={t(
								'pages.editor.components.form_animations.placeholder_height'
							)}
							autoComplete='off'
							value={frameSizeY}
							onChange={(event) =>
								onInputChangeDefault(event, setFrameSizeY)
							}
							onKeyPress={(event) => handleKeyPress(event)}
							required></input>
					</div>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_animations.frames_number'
						)}
					</label>
					<input
						maxLength='45'
						name='frames_amount'
						className='admin__container__inputs__in'
						id='frames_amount'
						type='text'
						placeholder={t(
							'pages.editor.components.form_animations.placeholder_frames_number'
						)}
						autoComplete='off'
						value={numbFrames}
						onChange={(event) =>
							onInputChangeDefault(event, setNumbFrames)
						}
						onKeyPress={(event) => handleKeyPressInteger(event)}
						required></input>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t('pages.editor.components.form_animations.duration')}
					</label>
					<input
						maxLength='45'
						name='frames_duration'
						className='admin__container__inputs__in'
						id='frames_duration'
						type='text'
						placeholder={t(
							'pages.editor.components.form_animations.placeholder_duration'
						)}
						autoComplete='off'
						value={msDuration}
						onChange={(event) =>
							onInputChangeDefault(event, setMsDuration)
						}
						onKeyPress={(event) => handleKeyPressInteger(event)}
						required></input>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t('pages.editor.components.form_animations.sound')}
					</label>
					<select
						name='sound'
						className='admin__container__inputs__in'
						id='sound'
						placeholder={t(
							'pages.editor.components.form_animations.placeholder_sound'
						)}
						autoComplete='off'
						value={sound}
						onChange={(event) =>
							onInputChangeDefault(event, setSound)
						}>
						<option key='' value=''>
							{t(
								'pages.editor.components.form_animations.select_sound'
							)}
						</option>
						{Object.keys(listSounds).map((key) => (
							<option key={key} value={key}>
								{listSounds[key].name}
							</option>
						))}
					</select>
				</div>

				{stateLoading === false ? (
					<div className='admin__container__boxBtn'>
						<SaveButton></SaveButton>
					</div>
				) : (
					<LoadingIcon />
				)}
			</form>
		</>
	)
}
