import { useRef, useState, useContext, createContext } from 'react'
import '../../styles/Inventory.scss'

import { API_STORE, API_STORE_BUY } from '../../utils/constants'

import { useUpdateProfileContext } from '../SceneDataFetch'

import { toast } from 'react-hot-toast'
import Coins from '../fragments/Coins'
import Spinner from '../shared/Spinner/Spinner'
import { useAuthProvider } from '../../context/AuthProvider/AuthProvider'
import { BASE_API } from '../../urls'
import { useSoundAction } from '../../hooks/useSoundAction'

const updateStoreContext = createContext(null)
let storeData = []

/**
 * Cuando se le da click a un objeto, tenga dinero o no, va a ocurrir lo siguiente:
 * El boton seleccionado, reemplaza su texto por un loading circular en la mitad
 * El resto de los botones se deshabilitan.
 *
 * La respuesta, sea success o error, se va a mostrar al final en un toaster.
 *
 * La idea es borrar el equipmentData cada vez que el usuario compra algo en la tienda.
 *
 * ¿Qué pasa si el usuario se mete a equipment antes de que se termine de completar la compra?
 * Se borra el equipmentData y se vuelve a hacer el request.
 * Ahora debería aparecer el nuevo objeto comprado, if success.
 *
 * Debe existir un componente llamado Inventary, Qué coordina si el usuario compró algo o no.
 *
 * La selección de la skin debería ser automatica.
 * Cambia el botón automaticamente, envía el request de que se ha cambiado.
 * Si saca error vuelve a repetir una y otra vez con un limite de 5 intentos.
 * Cada vez que se recarga el equipmentData debe volver a cambiar el boton del item
 * seleccionado. Por ende, el item selecionado si debe guardarlo el componente.
 */

const Store = ({ profileData, setStoreChanged }) => {
	const { axiosSupreme } = useAuthProvider()
	/**
	 * 
	 *Apenas entra hago el request de los datos
     Pero la idea es solo hacerlo una sola vez, entonces deberia guardarse en algún
     lugar. Y la proxima vez que se meta en la tienda, diria que si no estan ya los
     datos entonces que los traiga.
	 */
	const [, updateProfileData] = useUpdateProfileContext()
	const [purchaseProgress, setPurchaseProgress] = useState({
		isRunning: false,
		itemID: null,
	})
	// In case the user purchased successfully an item buy the store data
	// did not updated successfully.
	const purchasedItemsByID = useRef({})
	const [reload, setReload] = useState(false)

	async function purchaseItem(itemID) {
		// ------ Let the component know a purchase is in progress
		setPurchaseProgress((pp) => ({
			...pp,
			isRunning: true,
			itemID,
		}))

		// ----- #1 Wait for backend purchase confirmation
		try {
			// ----- Inform the equipment the store changed so it recharges when enter
			setStoreChanged(true)

			await axiosSupreme('post', BASE_API + API_STORE_BUY, {
				item: itemID,
				profile: profileData.id_profile,
			})

			toast.success('Item Comprado')
			// ----- Inform the equipment the store changed so it recharges when enter
			// setStoreChanged(true)

			// ----- #2 Wait the profile to update (coins)
			await updateProfileData()

			// ----- #3 Update store items again.
			const wasSuccess = await updateStoreData()
			if (!wasSuccess) {
				// if the data wasn't successfully loaded, ensure this item cannot be purchased again.
				// Dejas que siga comprando, más sin embargo deshabilitar este item.
				purchasedItemsByID.current[purchaseProgress.itemID] = true
			}

			// ----- Let the component know the process has end, erase the id.
			setPurchaseProgress((pp) => ({
				...pp,
				isRunning: false,
				itemID: null,
			}))
		} catch (error) {
			// ----- Let the component know the process has end, erase the id.
			setPurchaseProgress((pp) => ({
				...pp,
				isRunning: false,
				itemID: null,
			}))

			toast.error(
				'Ocurrio un error al hacer la compra, intentalo mas tarde'
			)
			// toast.error(error.message)
		}
	}

	async function updateStoreData() {
		try {
			storeData = await axiosSupreme(
				'get',
				BASE_API + API_STORE,
				profileData.id_profile + '/webp/'
			)
			// + API_STORE_ITEMS_CLOSE
			// As this data only comes with items not bouthed already, then erase this
			// only was for posible errors.
			purchasedItemsByID.current = {}

			// Reload the component
			setReload(!reload)
		} catch (error) {
			console.error('STORE DATA ERROR: ', error)
		}
	}

	// If first time and store data EMPTY THEN BRING THE DATA
	const once = useRef(false)
	if (!once.current && storeData.length === 0) {
		once.current = true

		updateStoreData()
	}

	return (
		<updateStoreContext.Provider
			value={{
				purchaseItem,
				purchaseProgress,
				purchasedItemsByID: purchasedItemsByID.current,
			}}>
			{storeData.results?.length > 0 ? (
				<>
					<div className='inventory' style={{ position: 'relative' }}>
						{storeData.results?.length !== 0 && (
							<Section
								items={storeData.results}
								totalCoinsHave={profileData.rewards}></Section>
						)}
					</div>
				</>
			) : (
				<div className='inventory'>
					<Spinner className={'spinnerPage'} />
				</div>
			)}
		</updateStoreContext.Provider>
	)
}

const Section = ({ items, totalCoinsHave }) => {
	return (
		<div className='inventory__section'>
			<div className='inventory__section__items'>
				{items.map((item, index) => (
					<Item
						key={index + item.id_item}
						id={item.id_item}
						name={item.name}
						image={item.image_preview_file}
						price={item.price}
						totalCoinsHave={totalCoinsHave}></Item>
				))}
			</div>
		</div>
	)
}

const Item = ({ id, name, image, price, totalCoinsHave }) => {
	return (
		<div
			className='inventory__section__items__item'
			id={'inventory__section__items__item' + id}>
			<div className='inventory__section__items__item__image__container'>
				<img src={image} alt='product item' loading='lazy' />
			</div>
			<div className='inventory__section__items__item__name'>
				<span>{name}</span>
			</div>
			<PurchaseButton
				id={id}
				price={price}
				totalCoinsHave={totalCoinsHave}></PurchaseButton>
		</div>
	)
}

const PurchaseButton = ({ id, price, totalCoinsHave }) => {
	const { purchaseItem, purchaseProgress, purchasedItemsByID } =
		useContext(updateStoreContext)

	const { soundAction } = useSoundAction()

	return (
		<button
			className='inventory__section__items__item__button inventory__section__items__item__button__buy'
			onClick={() => {
				purchaseItem(id)
				soundAction('effect', 'TLSI1', 'play')
			}}
			disabled={
				purchaseProgress.isRunning || purchasedItemsByID[id]
				// price > totalCoinsHave
			}>
			{purchasedItemsByID[id] !== undefined ? (
				'En tu inventario'
			) : purchaseProgress.isRunning && purchaseProgress.itemID === id ? (
				<Spinner
					className='spinnerCenter'
					style={{
						width: '1em',
						height: '1em',
						borderWidth: '2px',
					}}
				/>
			) : (
				<Coins coinsAmount={price} />
			)}
		</button>
	)
}

export default Store
