import React, { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../context/editor/ModalContext'
import { API_GET_CONTENT_MISSION_BY_MISSION } from '../../../../utils/constantsAdmin'
import { ContentMissionCollapse } from './ContentMissionCollapse'
import { Pagination } from '../../searchAndPagination/Pagination'
import { FormContentMission } from './FormContentMission'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { LoadingIcon } from '../../LoadingIcon'
import { useTranslation } from 'react-i18next'

export const ListContentMission = ({
	idObjectMission,
	idScene,
	typeMission,
	stateObjectMission,
}) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	//REFRESH CONTENT MISSION
	const [refreshMissionsContent, setRefreshMissionsContent] = useState(false)

	// DATA
	const [data, setData] = useState(undefined)

	// GET ALL MISSIONS CONTENT
	useEffect(() => {
		const getData = async () => {
			try {
				const result_data = await axiosSupreme(
					'get',
					API_GET_CONTENT_MISSION_BY_MISSION + idObjectMission + '/'
				)
				setData(result_data)
			} catch (e) {
				console.log('Error get Data: ', e)
			}
		}
		getData()
	}, [refreshMissionsContent])

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddContentMissionModal = () => {
		setTitleModal(t('pages.editor.components.list_content_mission.title'))
		setContentModal(
			<FormContentMission
				idObjectMission={idObjectMission}
				setRefreshMissionsContent={setRefreshMissionsContent}
				idScene={idScene}
				typeMission={typeMission}
			/>
		)
		openModal(true)
	}

	const handleKeyDown = (event) => {
		if (event.ctrlKey && event.key === 'b') {
			OpenAddContentMissionModal()
		}
	}

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown)

		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [])

	return (
		<div className='missionContentBodyCollapse'>
			<div className='missionContentBodyCollapse__btnAdd'>
				<h1>
					{t('pages.editor.components.list_content_mission.content')}
				</h1>
				<div className='boxBtnAdd'>
					{stateObjectMission.game ? (
						stateObjectMission.game.name === 'Memoria' ? (
							data !== undefined && data.results.length <= 5 ? (
								<button
									className='editorBtnActionAdd'
									onClick={OpenAddContentMissionModal}>
									{t('common.add')}
								</button>
							) : null
						) : (
							<button
								className='editorBtnActionAdd'
								onClick={OpenAddContentMissionModal}>
								{t('common.add')}
							</button>
						)
					) : (
						<button
							className='editorBtnActionAdd'
							onClick={OpenAddContentMissionModal}>
							{t('common.add')}
						</button>
					)}
				</div>
			</div>
			{data !== undefined ? (
				<>
					{data === 'search' || data.results.length > 0 ? (
						<>
							<SearchCamp
								setData={setData}
								setRefreshDataList={setRefreshMissionsContent}
								url={
									API_GET_CONTENT_MISSION_BY_MISSION +
									idObjectMission +
									'/'
								}
								search={search}
								setSearch={setSearch}
							/>
							{data === 'search' ? (
								<p className='noSelect'>
									{t(
										'pages.editor.components.list_content_mission.none_mission'
									)}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							{t(
								'pages.editor.components.list_content_mission.none_mission'
							)}
						</p>
					)}
					{data !== 'search' ? (
						<div className='missionContentBodyCollapse__Content'>
							{data.results.map((data_item, index) => (
								<ContentMissionCollapse
									key={data_item.id_mission_content_add_ons}
									idObjectMission={idObjectMission}
									objectContentMission={data_item}
									setRefreshMissionsContent={
										setRefreshMissionsContent
									}
									idScene={idScene}
									typeMission={typeMission}
								/>
							))}
							<Pagination
								data={data}
								setData={setData}></Pagination>
						</div>
					) : null}
				</>
			) : (
				<LoadingIcon />
			)}
		</div>
	)
}
