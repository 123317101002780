import { useState } from 'react'
import ModalContext from '../context/editor/ModalContext'

const ModalProvider = (props) => {
	const [isOpen, setIsOpen] = useState(false)
	const [titleModal, setTitleModal] = useState(false)
	const [contentModal, setContentModal] = useState(false)

	const openModal = (status) => {
		if (status) {
			setIsOpen(status)
			if (!status) {
				setContentModal(false)
			}
		} else {
			setIsOpen((prev) => !prev)
		}
	}

	return (
		<ModalContext.Provider
			value={{
				isOpen,
				openModal,
				titleModal,
				setTitleModal,
				contentModal,
				setContentModal,
			}}>
			{props.children}
		</ModalContext.Provider>
	)
}

export default ModalProvider
