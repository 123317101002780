import { useContext, useEffect, useState } from 'react'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import ModalContext from '../../../../context/editor/ModalContext'
import BodyCollapseAnimations from './BodyCollapseAnimations'
import { API_MANAGE_ANIMATIONS } from '../../../../utils/constantsAdmin'
import { FormAnimations } from './FormAnimations'
import AlertContext from '../../../../context/editor/AlertContext'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { toggleCollapse } from '../../../../utils/general_functions'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export default function CollapseAnimations({
	objectAnimation,
	setRefreshAnimations,
	activeCollapseAnimations,
	setActiveCollapseAnimations,
}) {
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// SET OBJECT ANIMATION
	const [stateObjectAnimation, setStateObjectAnimation] = useState(undefined)
	useEffect(() => {
		setStateObjectAnimation(objectAnimation)
	}, [objectAnimation])

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	const { t } = useTranslation()

	// UPDATE ANIMATION
	const UpdateAnimation = async () => {
		setTitleModal(
			t('pages.editor.components.collapse_animations.update_animation')
		)
		setContentModal(
			<FormAnimations
				stateObjectAnimation={stateObjectAnimation}
				setStateObjectAnimation={setStateObjectAnimation}
				setRefreshAnimations={setRefreshAnimations}
			/>
		)
		openModal(true)
	}

	// DELETE DECORATION
	const DeleteAnimation = async () => {
		try {
			const urlRequest =
				API_MANAGE_ANIMATIONS + stateObjectAnimation.id_animation + '/'
			const result_data = await axiosSupreme(
				'delete',
				urlRequest,
				undefined
			)
			if (result_data.hasOwnProperty('response')) {
				openDelete()
				setTypeMessage('error')
				setMessageAlert(t('common.cannot_delete'))
				showAlert()
			} else {
				setRefreshAnimations((prev) => !prev)
				openDelete()
			}
		} catch (e) {
			DeleteAnimation()
		}
	}

	return (
		<div className='adminComponents'>
			{stateObjectAnimation !== undefined ? (
				<>
					<div className='adminComponents__button collapseStylesBtn'>
						{activeCollapseAnimations ===
						objectAnimation.id_animation ? (
							<div className='arrowOpenCollapse'>
								<FaChevronUp />{' '}
							</div>
						) : (
							<div className='arrowOpenCollapse'>
								{' '}
								<FaChevronDown />
							</div>
						)}
						<div
							className='adminComponents__button__title title'
							onClick={() =>
								toggleCollapse(
									activeCollapseAnimations,
									setActiveCollapseAnimations,
									stateObjectAnimation.id_animation
								)
							}>
							<h2>{stateObjectAnimation.name}</h2>
						</div>
						<div className='adminComponents__button__options options'>
							<button
								className='editorBtnActionDelete'
								onClick={() => {
									setFunctionDelete(() => DeleteAnimation)
									setMessage(
										t(
											'pages.editor.components.collapse_animations.confirm_deleting_1'
										) +
											stateObjectAnimation.name +
											t(
												'pages.editor.components.collapse_animations.confirm_deleting_2'
											)
									)
									openDelete()
								}}>
								<p>
									<BsFillTrashFill />{' '}
								</p>
							</button>
							<button
								className='editorBtnActionEdit'
								onClick={UpdateAnimation}>
								<p>
									<BsFillPencilFill />
								</p>
							</button>
						</div>
					</div>
					{activeCollapseAnimations ===
						stateObjectAnimation.id_animation && (
						<BodyCollapseAnimations
							stateObjectAnimation={
								stateObjectAnimation
							}></BodyCollapseAnimations>
					)}
				</>
			) : null}
		</div>
	)
}
