import { useEffect, useState } from 'react'
import { API_MANAGE_GAME_LANGUAGE } from '../../../../utils/constantsAdmin'
import { returnProcessUrl } from '../../../../utils/general_functions_forms'
import { ItemGameLanguage } from './ItemGameLanguage'
import '../../../../styles/ListGameLanguage.scss'
import { ManageStorageGameLanguage } from '../../../../utils/manage_local_storage'
import { IoSadOutline } from 'react-icons/io5'
import { useFetchOptions } from '../../../../hooks/useFetchOptions/useFetchOptions'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'

export const ListGameLanguage = () => {
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// LOCAL STORAGE
	const { getGameLanguage, setGameLanguage } = ManageStorageGameLanguage()

	const [listGameLanguage, setListGameLanguage] = useState({})
	const [selectedGameLanguage, setSelectedGameLanguage] = useState(null)
	const [render, setRender] = useState(false)

	const { fetchOptions } = useFetchOptions()

	useEffect(() => {
		setListGameLanguage({})

		const updateDataList = (dataList) => {
			setListGameLanguage((prevData) => ({
				...prevData,
				...dataList.reduce((acc, dataItem) => {
					acc[dataItem.id_game_language] = {
						id_game_language: dataItem.id_game_language,
						base_language: dataItem.base_language,
						language_to_learn: dataItem.language_to_learn,
					}
					return acc
				}, {}),
			}))
		}

		const abortController = new AbortController()
		fetchOptions(
			API_MANAGE_GAME_LANGUAGE,
			updateDataList,
			abortController.signal
		)

		if (getGameLanguage()) {
			let idGameLanguage = JSON.parse(getGameLanguage())
			setSelectedGameLanguage(idGameLanguage.id_game_language)
		}

		return () => {
			abortController.abort()
		}
	}, [render])

	const selectLevelLanguage = () => {
		setGameLanguage(JSON.stringify(listGameLanguage[selectedGameLanguage]))
		window.location.reload()
	}

	return (
		<div className='containerListGameLanguages'>
			{Object.keys(listGameLanguage).length > 0 ||
			'view_administrator_game_admin' in gameUser.permissions ? (
				<div className='containerListGameLanguagesList'>
					<>
						{Object.keys(listGameLanguage).map((key) => (
							<ItemGameLanguage
								key={key}
								type={'existing'}
								objectGameLanguage={listGameLanguage[key]}
								selectedGameLanguage={selectedGameLanguage}
								setSelectedGameLanguage={
									setSelectedGameLanguage
								}
								setRender={setRender}
							/>
						))}
						{'view_administrator_game_admin' in
							gameUser.permissions && (
							<ItemGameLanguage
								type={'new'}
								setRender={setRender}
							/>
						)}
					</>
				</div>
			) : (
				<div className='containerListGameLanguagesListWarningPermissions'>
					<IoSadOutline />
					<h3>
						No tienes ningun nivel de lenguaje asignado ni tampoco
						permisos para agregar, comunicate con los
						administradores.
					</h3>
				</div>
			)}
			{selectedGameLanguage && (
				<button
					className='editorBtnActionAdd'
					onClick={selectLevelLanguage}>
					Seleccionar
				</button>
			)}
		</div>
	)
}
