import { useContext, useEffect, useState } from 'react'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import {
	API_MANAGE_LIST_LEVELS_LANGUAGES,
	API_MANAGE_STARS,
	API_MANAGE_WORLDS,
} from '../../../../utils/constantsAdmin'
import {
	processErrors,
	returnProcessUrl,
} from '../../../../utils/general_functions_forms'
import SaveButton from '../../SaveButton'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'
import { ManageStorageGameLanguage } from '../../../../utils/manage_local_storage'
import { useFetchOptions } from '../../../../hooks/useFetchOptions/useFetchOptions'

export function FormWorlds({
	idLevelLanguage,
	stateObjectWorld,
	setStateObjectWorld,
	setRefreshWorlds,
}) {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()

	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// LOCAL STORAGE
	const { getGameLanguage } = ManageStorageGameLanguage()

	// VALUES FORM
	const [name, setName] = useState('')
	const [order, setOrder] = useState('')
	const [star, setStar] = useState('')
	const [levelLanguage, setLevelLanguage] = useState('')

	// GET ALL STARS ANS LEVEL LANGUAGES
	const [listStars, setListStars] = useState({})
	const [listLevelLanguages, setListLevelLanguages] = useState({})
	let idGameLanguage = JSON.parse(getGameLanguage())

	const { fetchOptions } = useFetchOptions()

	useEffect(() => {
		const abortControllerListStars = new AbortController()
		const abortControllerLevelLanguages = new AbortController()

		const updateDataListStars = (dataList) => {
			setListStars((prevData) => ({
				...prevData,
				...dataList.reduce((acc, dataItem) => {
					acc[dataItem.id_star] = {
						name: dataItem.name,
						image_file: dataItem.image_file,
					}
					return acc
				}, {}),
			}))
		}

		fetchOptions(
			API_MANAGE_STARS,
			updateDataListStars,
			abortControllerListStars.signal
		)

		if (stateObjectWorld !== undefined) {
			const updateDataLevelLanguages = (dataList) => {
				setListLevelLanguages((prevData) => ({
					...prevData,
					...dataList.reduce((acc, dataItem) => {
						acc[dataItem.id_level_language] = {
							name: dataItem.name,
						}
						return acc
					}, {}),
				}))
			}

			fetchOptions(
				API_MANAGE_LIST_LEVELS_LANGUAGES +
					idGameLanguage.id_game_language +
					'/',
				updateDataLevelLanguages,
				abortControllerLevelLanguages.signal
			)
		}

		return () => {
			abortControllerListStars.abort()
			abortControllerLevelLanguages.abort()
		}
	}, [])

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		setOrder('')
	}

	// SET WORLD WHEN UPDATE
	useEffect(() => {
		if (stateObjectWorld !== undefined) {
			setName(stateObjectWorld.name)
			setOrder(stateObjectWorld.order)
			if (stateObjectWorld.star) {
				setStar(stateObjectWorld.star.id_star)
			}
			setLevelLanguage(stateObjectWorld.level_language.id_level_language)
		} else {
			clearFields()
		}
	}, [stateObjectWorld])

	// SUBMIT FORM WORLD
	const onSubmitWorld = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		if (stateObjectWorld !== undefined) {
			formData.append('level_language', levelLanguage)
		} else {
			formData.append('level_language', idLevelLanguage)
		}
		formData.append('name', name)
		formData.append('order', order)
		if (
			event.target.elements.stars.value !==
			t('pages.editor.components.form_worlds.select_stars')
		) {
			formData.append('star', star)
		} else {
			formData.append('star', '')
		}
		if (stateObjectWorld === undefined) {
			// SAVE
			manageWorld(event, API_MANAGE_WORLDS, formData, 'add')
		} else {
			// UPDATE
			manageWorld(
				event,
				API_MANAGE_WORLDS + stateObjectWorld.id_world + '/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE WORLD
	const manageWorld = async (event, url, obj, action) => {
		try {
			// PROCESS DATA
			await axiosSupreme(action === 'add' ? 'post' : 'patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			if (action === 'add') setRefreshWorlds((prev) => !prev)

			if (action === 'update') {
				if (levelLanguage !== idLevelLanguage) {
					setRefreshWorlds((prev) => !prev)
				}
				getNewObject()
			}
			openModal(false)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	const getNewObject = async () => {
		const result_data = await axiosSupreme(
			'get',
			API_MANAGE_WORLDS + stateObjectWorld.id_world + '/',
			undefined
		)
		setStateObjectWorld(result_data)
	}

	const handleKeyPress = (event) => {
		const charCode = event.charCode || event.keyCode
		if (charCode < 48 || charCode > 57) {
			event.preventDefault()
		}
	}

	const onInputChange = (event, setFunction) => {
		setFunction(event.target.value)
	}

	return (
		<>
			<form onSubmit={onSubmitWorld}>
				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t('pages.editor.components.form_worlds.order')}
					</label>
					<input
						min='1'
						max='999'
						name='order'
						className='admin__container__inputs__in'
						id='order'
						type='number'
						placeholder={t(
							'pages.editor.components.form_worlds.order_placeholder'
						)}
						autoComplete='off'
						value={order}
						onChange={(event) => onInputChange(event, setOrder)}
						onKeyPress={(event) => handleKeyPress(event)}
						required></input>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t('pages.editor.components.form_worlds.name')}
					</label>
					<input
						maxLength='45'
						name='name'
						className='admin__container__inputs__in'
						id='name'
						type='text'
						placeholder={t(
							'pages.editor.components.form_worlds.name_placeholder'
						)}
						autoComplete='off'
						value={name}
						onChange={(event) => onInputChange(event, setName)}
						required></input>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t('pages.editor.components.form_worlds.list_stars')}
					</label>
					<select
						name='stars'
						className='admin__container__inputs__in'
						id='stars'
						placeholder={t(
							'pages.editor.components.form_worlds.stars_placeholder'
						)}
						autoComplete='off'
						value={star}
						onChange={(event) => onInputChange(event, setStar)}>
						<option key='' value={undefined}>
							{t(
								'pages.editor.components.form_worlds.select_stars'
							)}
						</option>
						{Object.keys(listStars).map((key) => (
							<option key={key} value={key}>
								{listStars[key].name}
							</option>
						))}
					</select>
				</div>

				{stateObjectWorld !== undefined ? (
					<div
						className='admin__container__inputs'
						id='admin__container__inputs'>
						<label
							className='admin__container__inputs__title'
							forhtml='admin__container__inputs__title'>
							{t(
								'pages.editor.components.form_worlds.level_language'
							)}
						</label>
						<select
							name='level_language'
							className='admin__container__inputs__in'
							id='level_language'
							placeholder={t(
								'pages.editor.components.form_worlds.stars_placeholder'
							)}
							autoComplete='off'
							value={levelLanguage}
							onChange={(event) =>
								onInputChange(event, setLevelLanguage)
							}>
							<option key='' value={undefined}>
								{t(
									'pages.editor.components.form_worlds.select_world'
								)}
							</option>
							{Object.keys(listLevelLanguages).map((key) => (
								<option key={key} value={key}>
									{listLevelLanguages[key].name}
								</option>
							))}
						</select>
					</div>
				) : null}

				{stateLoading === false ? (
					<div className='admin__container__boxBtn'>
						<SaveButton></SaveButton>
					</div>
				) : (
					<LoadingIcon />
				)}
			</form>
		</>
	)
}
