import React, { useEffect, useState } from 'react'
import { ListSceneSubWorld } from '../ScenesSubWorlds/ListSceneSubWorld'
import { ManageStorageSceneSubworld } from '../../../../utils/manage_local_storage'
import { useTranslation } from 'react-i18next'

export default function BodyCollapseSubWorld({ stateObjectSubWorld }) {
	const { t } = useTranslation()

	// LOCAL STORAGE
	const { removeSceneSubworld, setSceneSubworld, getSceneSubworld } =
		ManageStorageSceneSubworld()

	//BUTTONS SCENE OR SUBWORLDS
	const [scene, setScene] = useState(false)
	const showScene = () => {
		if (scene) {
			setScene(false)
			removeSceneSubworld()
		} else {
			setScene(true)
			setSceneSubworld('YES')
		}
	}

	// const OpenVideo = (url_video) => {
	// 	setTitleModal(t('pages.editor.components.body_collapse_subworld.video'))
	// 	setContentModal(
	// 		<div className='videoWorld'>
	// 			<video controls autoPlay>
	// 				<source src={url_video} />
	// 			</video>
	// 		</div>
	// 	)
	// 	openModal(true)
	// }

	useEffect(() => {
		if (getSceneSubworld()) {
			setScene(true)
		}
	}, [])

	return (
		<div className='subWorld__component collapseStylesBody'>
			<div className='subWorld__component__description'>
				<p>
					<strong>
						{t(
							'pages.editor.components.body_collapse_subworld.description'
						)}
					</strong>
				</p>
				<p>{stateObjectSubWorld.description}</p>
			</div>

			<div className='subWorld__component__btns'>
				<button
					className={
						scene
							? 'editorBtnActionAddActive'
							: 'editorBtnActionAdd'
					}
					onClick={showScene}>
					{t(
						'pages.editor.components.body_collapse_subworld.maps_subworld'
					)}
				</button>
			</div>

			<div className='subWorld__component__scene'>
				{scene && (
					<ListSceneSubWorld
						idSubWorld={stateObjectSubWorld.id_sub_world}
					/>
				)}
			</div>
		</div>
	)
}
