import { useContext, useEffect, useRef, useState } from 'react'
import AlertContext from '../../../../../context/editor/AlertContext'
import ModalContext from '../../../../../context/editor/ModalContext'
import {
	API_MANAGE_GAMES,
	API_MANAGE_SOUNDS,
} from '../../../../../utils/constantsAdmin'
import {
	onInputChangeDefault,
	onInputChangeSound,
	processErrors,
} from '../../../../../utils/general_functions_forms'
import SaveButton from '../../../SaveButton'
import { LoadingIcon } from '../../../LoadingIcon'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'
import { useFetchOptions } from '../../../../../hooks/useFetchOptions/useFetchOptions'

export const FormGame = ({
	stateObjectGame,
	setStateObjectGame,
	setRefreshGames,
}) => {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()

	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [name, setName] = useState('')
	const [nameTranslation, setNameTranslation] = useState('')
	const [description, setDescription] = useState('')
	const [descriptionTranslation, setDescriptionTranslation] = useState('')
	const audioFile = useRef(null)
	const [sound, setSound] = useState('')

	// ERROR FOR FORM
	const [errorAudio, setErrorAudio] = useState('')

	// LIST VALUES
	const [listSounds, setListSounds] = useState({})

	const { fetchOptions } = useFetchOptions()

	useEffect(() => {
		const updateDataList = (dataList) => {
			setListSounds((prevData) => ({
				...prevData,
				...dataList.reduce((acc, data_item) => {
					acc[data_item.id_sound] = { name: data_item.name }
					return acc
				}, {}),
			}))
		}

		const abortController = new AbortController()
		fetchOptions(API_MANAGE_SOUNDS, updateDataList, abortController.signal)

		return () => {
			abortController.abort()
		}
	}, [])

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		setNameTranslation('')
		setDescription('')
		setDescriptionTranslation('')
		audioFile.current.value = null
	}

	// SET WORLD WHEN UPDATE
	useEffect(() => {
		if (stateObjectGame !== undefined) {
			setName(stateObjectGame.name)
			setNameTranslation(
				stateObjectGame.name_translation
					? stateObjectGame.name_translation
					: ''
			)
			setDescription(
				stateObjectGame.description ? stateObjectGame.description : ''
			)
			setDescriptionTranslation(
				stateObjectGame.description_translation
					? stateObjectGame.description_translation
					: ''
			)
			if (stateObjectGame.sound) {
				setSound(stateObjectGame.sound.id_sound)
			}
		} else {
			clearFields()
		}
	}, [stateObjectGame])

	// SUBMIT FORM GAME
	const onSubmitGame = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('name', event.target.elements.name.value)
		formData.append('sound', sound ? sound : '')
		if (event.target.elements.audio_file.value) {
			formData.append(
				'audio_file',
				event.target.elements.audio_file.files[0]
			)
		}
		formData.append(
			'name_translation',
			event.target.elements.name_translation.value
		)
		formData.append('description', event.target.elements.description.value)
		formData.append(
			'description_translation',
			event.target.elements.description_translation.value
		)
		if (stateObjectGame === undefined) {
			// SAVE
			manageGames(API_MANAGE_GAMES, formData, 'add')
		} else {
			// UPDATE
			manageGames(
				API_MANAGE_GAMES + stateObjectGame.id_game + '/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE GAME
	const manageGames = async (url, obj, action) => {
		try {
			if (action === 'add') {
				await axiosSupreme('post', url, obj)
			} else {
				await axiosSupreme('patch', url, obj)
			}
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			if (action === 'add') setRefreshGames((prev) => !prev)
			if (action === 'update') getNewObject((prev) => !prev)
			openModal(false)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	const getNewObject = async () => {
		let urlRequest = API_MANAGE_GAMES
		const result_data = await axiosSupreme(
			'get',
			(urlRequest += stateObjectGame.id_game + '/'),
			undefined
		)
		setStateObjectGame(result_data)
	}

	return (
		<>
			<form onSubmit={onSubmitGame}>
				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t('pages.editor.components.form_game.name')}
					</label>
					<input
						maxLength='45'
						name='name'
						className='admin__container__inputs__in'
						id='name'
						type='text'
						placeholder={t(
							'pages.editor.components.form_game.placeholder_name'
						)}
						autoComplete='off'
						value={name}
						onChange={(event) =>
							onInputChangeDefault(event, setName)
						}
						required></input>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_game.translation_name_and_placeholder'
						)}
					</label>
					<input
						maxLength='45'
						name='name_translation'
						className='admin__container__inputs__in'
						id='name_translation'
						type='text'
						placeholder={t(
							'pages.editor.components.form_game.translation_name_and_placeholder'
						)}
						autoComplete='off'
						value={nameTranslation}
						onChange={(event) =>
							onInputChangeDefault(event, setNameTranslation)
						}></input>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_game.description_and_placeholder'
						)}
					</label>
					<textarea
						maxLength='300'
						name='description'
						className='admin__container__inputs__in'
						id='description'
						type='text'
						placeholder={t(
							'pages.editor.components.form_game.description_and_placeholder'
						)}
						autoComplete='off'
						value={description}
						onChange={(event) =>
							onInputChangeDefault(event, setDescription)
						}></textarea>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_game.translation_description_and_placeholder'
						)}
					</label>
					<textarea
						maxLength='300'
						name='description_translation'
						className='admin__container__inputs__in'
						id='description_translation'
						type='text'
						placeholder={t(
							'pages.editor.components.form_game.translation_description_and_placeholder'
						)}
						autoComplete='off'
						value={descriptionTranslation}
						onChange={(event) =>
							onInputChangeDefault(
								event,
								setDescriptionTranslation
							)
						}></textarea>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t('pages.editor.components.form_game.audio')}
					</label>
					{stateObjectGame !== undefined ? (
						<>
							{stateObjectGame.audio_file !== null ? (
								<label htmlFor=''>
									{t('common.current_file')}
									<a
										href={stateObjectGame.audio_file}
										target='_blank'
										rel='noopener noreferrer'>
										{t('common.open')}
									</a>
								</label>
							) : null}
						</>
					) : null}
					<input
						name='audio_file'
						className='admin__container__inputs__in'
						id='audio_file'
						type='file'
						ref={audioFile}
						autoComplete='off'
						onChange={(event) =>
							onInputChangeSound(event, setErrorAudio, audioFile)
						}
						accept='audio/*'
						required={stateObjectGame === undefined}></input>
					{errorAudio && <p>{errorAudio}</p>}
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t('pages.editor.components.form_game.sound')}
					</label>
					<select
						name='sound'
						className='admin__container__inputs__in'
						id='sound'
						placeholder={t(
							'pages.editor.components.form_game.placeholder_sound'
						)}
						autoComplete='off'
						value={sound}
						onChange={(event) =>
							onInputChangeDefault(event, setSound)
						}>
						<option key='' value=''>
							{t(
								'pages.editor.components.form_game.select_sound'
							)}
						</option>
						{Object.keys(listSounds).map((key) => (
							<option key={key} value={key}>
								{listSounds[key].name}
							</option>
						))}
					</select>
				</div>

				{stateLoading === false ? (
					<div className='admin__container__boxBtn'>
						<SaveButton></SaveButton>
					</div>
				) : (
					<LoadingIcon />
				)}
			</form>
		</>
	)
}
