import React, { useContext, useEffect, useRef, useState } from 'react'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import {
	API_MANAGE_LIST_LEVELS_LANGUAGES,
	API_MANAGE_SUBWORLDS,
	GET_WORLDS_BY_LEVEL,
} from '../../../../utils/constantsAdmin'

import SaveButton from '../../SaveButton'
import {
	onInputChangeDefault,
	processErrors,
} from '../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'
import { ManageStorageGameLanguage } from '../../../../utils/manage_local_storage'
import { useFetchOptions } from '../../../../hooks/useFetchOptions/useFetchOptions'

export default function FormSubWorld({
	idWorld,
	stateObjectSubWorld,
	setStateObjectSubWorld,
	setRefreshSubWorlds,
}) {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// LOCAL STORAGE
	const { getGameLanguage } = ManageStorageGameLanguage()

	// VALUES FORM
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const [order, setOrder] = useState('')
	const [levelLanguage, setLevelLanguage] = useState('')
	const [world, setWorld] = useState('')

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// GET ALL STARS ANS LEVEL LANGUAGES
	const [listLevelLanguages, setListLevelLanguages] = useState({})
	const [listWorlds, setListWorlds] = useState({})
	let idGameLanguage = JSON.parse(getGameLanguage())

	const abortControllerListWorldRef = useRef()

	const { fetchOptions } = useFetchOptions()

	useEffect(() => {
		const abortControllerLevelLanguages = new AbortController()
		const abortControllerListWorld = new AbortController()

		if (stateObjectSubWorld !== undefined) {
			const updateDataLevelLanguages = (dataList) => {
				setListLevelLanguages((prevData) => ({
					...prevData,
					...dataList.reduce((acc, dataItem) => {
						acc[dataItem.id_level_language] = {
							name: dataItem.name,
						}
						return acc
					}, {}),
				}))
			}

			fetchOptions(
				API_MANAGE_LIST_LEVELS_LANGUAGES +
					idGameLanguage.id_game_language +
					'/',
				updateDataLevelLanguages,
				abortControllerLevelLanguages.signal
			)

			const updateDataListWorld = (dataList) => {
				console.log('OBSERVAR ESTO: ', dataList)
				setListWorlds((prevData) => ({
					...prevData,
					...dataList.reduce((acc, dataItem) => {
						acc[dataItem.id_world] = { name: dataItem.name }
						return acc
					}, {}),
				}))
			}

			fetchOptions(
				GET_WORLDS_BY_LEVEL +
					stateObjectSubWorld.world.level_language +
					'/',
				updateDataListWorld,
				abortControllerListWorld.signal
			)
		}

		return () => {
			abortControllerLevelLanguages.abort()
			abortControllerListWorld.abort()
		}
	}, [])

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		setOrder('')
		setDescription('')
	}

	// SET SUBWORLD WHEN UPDATE
	useEffect(() => {
		if (stateObjectSubWorld !== undefined) {
			setName(stateObjectSubWorld.name)
			setDescription(stateObjectSubWorld.description)
			setOrder(stateObjectSubWorld.order)
			setLevelLanguage(stateObjectSubWorld.world.level_language)
			setWorld(stateObjectSubWorld.world.id_world)
		} else {
			clearFields()
		}
	}, [stateObjectSubWorld])

	// SUBMIT FORM WORLD
	const onSubmitSubWorld = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		if (stateObjectSubWorld !== undefined) {
			formData.append('world', world)
		} else {
			formData.append('world', idWorld)
		}
		formData.append('order', order)
		formData.append('name', name)
		formData.append('description', description)
		if (stateObjectSubWorld === undefined) {
			// SAVE
			manageSubWorld(event, API_MANAGE_SUBWORLDS, formData, 'add')
		} else {
			// UPDATE
			manageSubWorld(
				event,
				API_MANAGE_SUBWORLDS + stateObjectSubWorld.id_sub_world + '/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE SUBWORLD
	const manageSubWorld = async (event, url, obj, action) => {
		try {
			await axiosSupreme(action === 'add' ? 'post' : 'patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			if (action === 'add') setRefreshSubWorlds((prev) => !prev)
			if (action === 'update') {
				getNewObject()
				if (idWorld !== world) {
					setRefreshSubWorlds((prev) => !prev)
				}
			}
			openModal(false)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	const getNewObject = async () => {
		const result_data = await axiosSupreme(
			'get',
			API_MANAGE_SUBWORLDS + stateObjectSubWorld.id_sub_world + '/',
			undefined
		)
		setStateObjectSubWorld(result_data)
	}

	const onChangeLevelLanguage = (event) => {
		setLevelLanguage(event.target.value)
		setWorld('')
		setListWorlds({})
		if (event.target.value) {
			if (
				abortControllerListWorldRef.current instanceof AbortController
			) {
				abortControllerListWorldRef.current.abort()
			}

			abortControllerListWorldRef.current = new AbortController()

			const updateDataListWorld = (dataList) => {
				setListWorlds((prevData) => ({
					...prevData,
					...dataList.reduce((acc, dataItem) => {
						acc[dataItem.id_world] = { name: dataItem.name }
						return acc
					}, {}),
				}))
			}

			fetchOptions(
				GET_WORLDS_BY_LEVEL + event.target.value + '/',
				updateDataListWorld,
				abortControllerListWorldRef.current.signal
			)
		}
	}

	return (
		<form onSubmit={onSubmitSubWorld}>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t('pages.editor.components.form_subworld.order')}
				</label>
				<input
					maxLength='300'
					name='order'
					className='admin__container__inputs__in'
					id='order'
					type='number'
					placeholder={t(
						'pages.editor.components.form_subworld.order_placeholder'
					)}
					autoComplete='off'
					value={order}
					onChange={(event) => onInputChangeDefault(event, setOrder)}
					required></input>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t('pages.editor.components.form_subworld.name')}
				</label>
				<input
					maxLength='300'
					name='name'
					className='admin__container__inputs__in'
					id='name'
					type='text'
					placeholder={t(
						'pages.editor.components.form_subworld.name_placeholder'
					)}
					autoComplete='off'
					value={name}
					onChange={(event) => onInputChangeDefault(event, setName)}
					required></input>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t('pages.editor.components.form_subworld.description')}
				</label>
				<textarea
					maxLength='300'
					name='description'
					className='admin__container__inputs__in'
					id='description'
					type='text'
					placeholder={t(
						'pages.editor.components.form_subworld.description_placeholder'
					)}
					autoComplete='off'
					value={description}
					onChange={(event) =>
						onInputChangeDefault(event, setDescription)
					}
					required></textarea>
			</div>

			{setStateObjectSubWorld !== undefined ? (
				<>
					<div
						className='admin__container__inputs'
						id='admin__container__inputs'>
						<label
							className='admin__container__inputs__title'
							forhtml='admin__container__inputs__title'>
							{t(
								'pages.editor.components.form_subworld.level_language'
							)}
						</label>
						<select
							name='level_language'
							className='admin__container__inputs__in'
							id='level_language'
							placeholder={t(
								'pages.editor.components.form_subworld.stars_placeholder'
							)}
							autoComplete='off'
							value={levelLanguage}
							onChange={(event) => onChangeLevelLanguage(event)}
							required>
							<option key='' value=''>
								{t(
									'pages.editor.components.form_subworld.select'
								)}
							</option>
							{Object.keys(listLevelLanguages).map((key) => (
								<option key={key} value={key}>
									{listLevelLanguages[key].name}
								</option>
							))}
						</select>
					</div>

					<div
						className='admin__container__inputs'
						id='admin__container__inputs'>
						<label
							className='admin__container__inputs__title'
							forhtml='admin__container__inputs__title'>
							{t('pages.editor.components.form_subworld.world')}
						</label>
						<select
							name='level_language'
							className='admin__container__inputs__in'
							id='level_language'
							placeholder={t(
								'pages.editor.components.form_subworld.stars_placeholder'
							)}
							autoComplete='off'
							value={world}
							onChange={(event) =>
								onInputChangeDefault(event, setWorld)
							}
							required>
							<option key='' value=''>
								{t(
									'pages.editor.components.form_subworld.select'
								)}
							</option>
							{Object.keys(listWorlds).map((key) => (
								<option key={key} value={key}>
									{listWorlds[key].name}
								</option>
							))}
						</select>
					</div>
				</>
			) : null}

			{stateLoading === false ? (
				<div className='admin__container__boxBtn'>
					<SaveButton></SaveButton>
				</div>
			) : (
				<LoadingIcon />
			)}
		</form>
	)
}
