import React, { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../context/editor/ModalContext'
import { ListGameLanguage } from './GameLanguages/ListGameLanguage'
import { FaArrowRight } from 'react-icons/fa'
import { FaQuestion } from 'react-icons/fa'
//Flags
import suomiFlag from '../../../assets/Flags/bandera-finlandia.png'
import spainFlag from '../../../assets/Flags/bandera-espana.png'
import deutschFlag from '../../../assets/Flags/bandera-alemania.png'
import francaisFlag from '../../../assets/Flags/bandera-francia.png'
import svenskaFlag from '../../../assets/Flags/bandera-suecia.png'
import englishFlag from '../../../assets/Flags/bandera-inglaterra.webp'
import firstGrade from '../../../assets/Flags/number_one.png'
import fifthGrade from '../../../assets/Flags/number_five.webp'
import { ManageStorageGameLanguage } from '../../../utils/manage_local_storage'

export const ButtonAdminGameLanguage = () => {
	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	const [baseLanguage, setBaseLanguage] = useState(null)
	const [languageToLearn, setLanguageToLearn] = useState(null)

	// LOCAL STORAGE
	const { getGameLanguage } = ManageStorageGameLanguage()

	const onClickChangeLevelLanguage = () => {
		setTitleModal('Lenguajes de Juego')
		setContentModal(<ListGameLanguage />)
		openModal(true)
	}

	useEffect(() => {
		window.scroll(0, 0)
		let valueGameLanguage = getGameLanguage()
		if (valueGameLanguage) {
			valueGameLanguage = JSON.parse(valueGameLanguage)
			setBaseLanguage(valueGameLanguage.base_language)
			setLanguageToLearn(valueGameLanguage.language_to_learn)
		}
	}, [])

	return (
		<>
			<button
				className='gameLanguageButton'
				onClick={onClickChangeLevelLanguage}>
				{baseLanguage ? (
					<img
						src={
							baseLanguage === 'español'
								? spainFlag
								: baseLanguage === 'soumi'
								? suomiFlag
								: baseLanguage === 'deutsch'
								? deutschFlag
								: baseLanguage === 'francais'
								? francaisFlag
								: baseLanguage === 'svenska'
								? svenskaFlag
								: baseLanguage === 'english'
								? englishFlag
								: baseLanguage === 'first_grade'
								? firstGrade
								: baseLanguage === 'fifth_grade'
								? fifthGrade
								: ''
						}
						className='gameLanguageButtonImage'
						alt='flagLanguage'
					/>
				) : (
					<FaQuestion />
				)}
				<FaArrowRight />
				{languageToLearn ? (
					<img
						src={
							languageToLearn === 'español'
								? spainFlag
								: languageToLearn === 'soumi'
								? suomiFlag
								: languageToLearn === 'deutsch'
								? deutschFlag
								: languageToLearn === 'francais'
								? francaisFlag
								: languageToLearn === 'svenska'
								? svenskaFlag
								: languageToLearn === 'english'
								? englishFlag
								: languageToLearn === 'first_grade'
								? firstGrade
								: languageToLearn === 'fifth_grade'
								? fifthGrade
								: ''
						}
						className='gameLanguageButtonImage'
						alt='flagLanguage'
					/>
				) : (
					<FaQuestion />
				)}
			</button>
		</>
	)
}
