import { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../../context/editor/ModalContext'

import { API_MANAGE_STORE_ITEMS } from '../../../../../utils/constantsAdmin'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import { FormItems } from './FormItems'
import { ItemBodyCollapse } from './ItemBodyCollapse'
import { toggleCollapse } from '../../../../../utils/general_functions'
import DeleteContext from '../../../../../context/editor/DeleteContext'
import AlertContext from '../../../../../context/editor/AlertContext'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ItemCollapse = ({
	objectItem,
	setRefreshItems,
	activeCollapse,
	setActiveCollapse,
}) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// SET OBJECT ITEM
	const [stateObjectItem, setStateObjectItem] = useState(undefined)
	useEffect(() => {
		setStateObjectItem(objectItem)
	}, [objectItem])

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	//UPDATE ITEM
	const UpdateItem = async () => {
		setTitleModal(t('pages.editor.components.item_collapse.update_item'))
		setContentModal(
			<FormItems
				stateObjectItem={stateObjectItem}
				setStateObjectItem={setStateObjectItem}
				setRefreshItems={setRefreshItems}
			/>
		)
		openModal(true)
	}

	// DELETE ITEM
	const DeleteItem = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_STORE_ITEMS + stateObjectItem.id_item + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(t('common.cannot_delete'))
			showAlert()
		} else {
			setRefreshItems((prev) => !prev)
			openDelete()
		}
	}

	return (
		<div className='itemsAdmin'>
			{stateObjectItem !== undefined ? (
				<>
					<div className='itemsAdmin__nameAndOptions collapseStylesBtn'>
						{activeCollapse === objectItem.id_item ? (
							<div className='arrowOpenCollapse'>
								<FaChevronUp />{' '}
							</div>
						) : (
							<div className='arrowOpenCollapse'>
								{' '}
								<FaChevronDown />
							</div>
						)}
						<div
							className='nameAndUsable title'
							onClick={() =>
								toggleCollapse(
									activeCollapse,
									setActiveCollapse,
									stateObjectItem.id_item
								)
							}>
							<h1>
								{' '}
								{stateObjectItem.name +
									t(
										'pages.editor.components.item_collapse.price_code'
									) +
									stateObjectItem.price}{' '}
							</h1>
						</div>
						<div className='itemsAdmin__nameAndOptions__options'>
							<button
								className='editorBtnActionDelete'
								onClick={() => {
									setFunctionDelete(() => DeleteItem)
									setMessage(
										t(
											'pages.editor.components.item_collapse.confirm_deleting_1'
										) +
											stateObjectItem.name +
											'?'
									)
									openDelete()
								}}>
								<p>
									<BsFillTrashFill />{' '}
								</p>
							</button>
							<button
								className='editorBtnActionEdit'
								onClick={UpdateItem}>
								<p>
									<BsFillPencilFill />
								</p>
							</button>
						</div>
					</div>
					{activeCollapse === stateObjectItem.id_item && (
						<ItemBodyCollapse
							stateObjectItem={
								stateObjectItem
							}></ItemBodyCollapse>
					)}
				</>
			) : null}
		</div>
	)
}
