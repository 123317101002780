import '../../../../styles/AdminDesign.scss'
import { useContext, useEffect, useState } from 'react'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import { Pagination } from '../../searchAndPagination/Pagination'
import { useNavigate } from 'react-router-dom'
import {
	GET_DECORATIONS_BY_SCENE,
	GET_DECORATIONS_BY_SCENE_SUB_WORLD,
} from '../../../../utils/constantsAdmin'
import { CollapseDecorations } from './CollapseDecorations'
import ModalContext from '../../../../context/editor/ModalContext'
import { FormDecorationScene } from './FormDecorationScene'
import { ManageStorageAdminDesigner } from '../../../../utils/local_storage'
import { LoadingIcon } from '../../LoadingIcon'
import { FaBahai } from 'react-icons/fa'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ListDecorationsScene = ({ idScene }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	const { gettypeContextSpace } = ManageStorageAdminDesigner()
	const navigate = useNavigate()

	// REFRESH DECORATIONS
	const [refreshDecorations, setRefreshDecorations] = useState(false)

	// DATA
	const [dataScene, setDataScene] = useState(undefined)

	// GET ALL DECORATIONS
	useEffect(() => {
		const getData = async () => {
			let url
			if (gettypeContextSpace() === 'World') {
				url = GET_DECORATIONS_BY_SCENE + idScene + '/'
			} else if (gettypeContextSpace() === 'Subworld') {
				url = GET_DECORATIONS_BY_SCENE_SUB_WORLD + idScene + '/'
			}
			try {
				const result_data = await axiosSupreme('get', url)
				setDataScene(result_data)
			} catch (e) {
				console.log('Error get Data: ', e)
			}
		}
		getData()
	}, [refreshDecorations])

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL,
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddDecorationsModal = () => {
		setTitleModal(t('pages.editor.components.list_decorations_scene.title'))
		setContentModal(
			<FormDecorationScene
				idScene={idScene}
				setRefreshDecorations={setRefreshDecorations}
			/>
		)
		openModal(true)
	}

	// ACTIVECOLLAPSE
	const [activeCollapseDecorations, setActiveCollapseDecorations] =
		useState(undefined)

	const ReDirectAnimations = (action) => {
		navigate('/manage-animations')
	}

	return (
		<div className='decorationSceneWorld'>
			<div className='decorationSceneWorld__btnAnimations'>
				<div className='boxBtnAnimations'>
					<button className='btnAdd' onClick={ReDirectAnimations}>
						{' '}
						<p className='pIcons'>
							<FaBahai />{' '}
						</p>{' '}
						{t(
							'pages.editor.components.list_decorations_scene.animations'
						)}
					</button>
				</div>
			</div>

			<div className='decorationSceneWorld__btnAdd'>
				<h1>
					{t(
						'pages.editor.components.list_decorations_scene.escene_decorations'
					)}
				</h1>
				<div className='boxBtnAdd'>
					<button
						className='editorBtnActionAdd'
						onClick={OpenAddDecorationsModal}>
						{t('common.add')}
					</button>
				</div>
			</div>

			<div className='decorationSceneWorld__content'>
				{dataScene !== undefined ? (
					<>
						{dataScene === 'search' ||
						dataScene.results.length > 0 ? (
							<>
								<SearchCamp
									setData={setDataScene}
									setRefreshDataList={setRefreshDecorations}
									url={
										gettypeContextSpace() === 'World'
											? GET_DECORATIONS_BY_SCENE +
											  idScene +
											  '/'
											: GET_DECORATIONS_BY_SCENE_SUB_WORLD +
											  idScene +
											  '/'
									}
									search={search}
									setSearch={setSearch}
								/>
								{dataScene === 'search' ? (
									<p className='noSelect'>
										{t(
											'pages.editor.components.list_decorations_scene.none_decoration'
										)}
									</p>
								) : null}
							</>
						) : (
							<p className='noSelect'>
								{t(
									'pages.editor.components.list_decorations_scene.escene_no_asigned_decoration'
								)}
							</p>
						)}
						{dataScene !== 'search' ? (
							<div className='decorationSceneWorld__content__listDecorations'>
								{dataScene.results.map((data_item, index) => (
									<CollapseDecorations
										key={
											data_item.hasOwnProperty(
												'id_world_scene_decoration'
											)
												? data_item.id_world_scene_decoration
												: data_item.id_sub_world_scene_decoration
										}
										idScene={idScene}
										objectDecoration={data_item}
										setRefreshDecorations={
											setRefreshDecorations
										}
										activeCollapseDecorations={
											activeCollapseDecorations
										}
										setActiveCollapseDecorations={
											setActiveCollapseDecorations
										}></CollapseDecorations>
								))}
								<Pagination
									data={dataScene}
									setData={setDataScene}></Pagination>
							</div>
						) : null}
					</>
				) : (
					<LoadingIcon />
				)}
			</div>
		</div>
	)
}
