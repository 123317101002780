import { useContext, useEffect, useState } from 'react'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import { ManageStorageAdminDesigner } from '../../../../utils/local_storage'
import {
	API_MANAGE_ANIMATIONS,
	API_MANAGE_DECORATIONS,
	API_MANAGE_DECORATIONS_SUB_WORLD,
} from '../../../../utils/constantsAdmin'
import SaveButton from '../../SaveButton'
import {
	onInputChangeDefault,
	processErrors,
} from '../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'
import { useFetchOptions } from '../../../../hooks/useFetchOptions/useFetchOptions'

export const FormDecorationScene = ({
	idScene,
	stateObjectDecoration,
	setStateObjectDecoration,
	setRefreshDecorations,
}) => {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()
	// LOCAL STORAGE
	const { gettypeContextSpace } = ManageStorageAdminDesigner()

	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [xPosition, setXPosition] = useState('')
	const [yPosition, setYPosition] = useState('')
	const [animation, setAnimation] = useState('')
	const [isSuperimposed, setIsSuperimposed] = useState('')
	const [hidden, setHidden] = useState('')
	const [imageAnimation, setImageAnimation] = useState(undefined)

	// GET ALL ANIMATIONS
	const [listAnimations, setListAnimations] = useState({})

	const { fetchOptions } = useFetchOptions()

	useEffect(() => {
		const abortController = new AbortController()

		const updateDataList = (dataList) => {
			setListAnimations((prevData) => ({
				...prevData,
				...dataList.reduce((acc, dataItem) => {
					acc[dataItem.id_animation] = {
						name: dataItem.name,
						image_file: dataItem.image_file,
					}
					return acc
				}, {}),
			}))
		}

		fetchOptions(
			API_MANAGE_ANIMATIONS,
			updateDataList,
			abortController.signal
		)

		return () => {
			abortController.abort()
		}
	}, [])

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setXPosition('')
		setYPosition('')
		setAnimation('')
		setIsSuperimposed('')
		setHidden('')
	}

	// SET LEVEL WHEN UPDATE
	useEffect(() => {
		if (stateObjectDecoration !== undefined) {
			setXPosition(
				stateObjectDecoration.coordinates
					.replace('[', '')
					.replace(']', '')
					.split(',')[0]
			)
			setYPosition(
				stateObjectDecoration.coordinates
					.replace('[', '')
					.replace(']', '')
					.split(',')[1]
			)
			setAnimation(stateObjectDecoration.animation.id_animation)
			setImageAnimation(stateObjectDecoration.animation.image_file)
			setIsSuperimposed(stateObjectDecoration.is_superimposed)
			if (stateObjectDecoration.hidden === null) {
				setHidden(false)
			} else {
				setHidden(stateObjectDecoration.hidden)
			}
		} else {
			clearFields()
		}
	}, [stateObjectDecoration])

	// SUBMIT FORM LEVEL
	const onSubmitLevel = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append(
			'coordinates',
			'[' +
				event.target.elements.xPosition.value +
				',' +
				event.target.elements.yPosition.value +
				']'
		)
		formData.append('animation', event.target.elements.animation.value)
		formData.append(
			'is_superimposed',
			event.target.elements.is_superimposed.value
		)
		formData.append('hidden', event.target.elements.hidden.value)
		formData.append(
			gettypeContextSpace() === 'World'
				? 'world_scene'
				: 'sub_world_scene',
			idScene
		)

		let urlRequest =
			gettypeContextSpace() === 'World'
				? API_MANAGE_DECORATIONS
				: API_MANAGE_DECORATIONS_SUB_WORLD
		if (stateObjectDecoration === undefined) {
			// SAVE
			manageLevel(event, urlRequest, formData, 'add')
		} else {
			// UPDATE
			manageLevel(
				event,
				(urlRequest +=
					(gettypeContextSpace() === 'World'
						? stateObjectDecoration.id_world_scene_decoration
						: stateObjectDecoration.id_sub_world_scene_decoration) +
					'/'),
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE LEVEL
	const manageLevel = async (event, url, obj, action) => {
		try {
			// PROCESS DATA
			await axiosSupreme(action === 'add' ? 'post' : 'patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			if (action === 'add') setRefreshDecorations((prev) => !prev)
			if (action === 'update') getNewObject()
			setStateLoading(false)
			openModal(false)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	const getNewObject = async () => {
		let urlRequest =
			gettypeContextSpace() === 'World'
				? API_MANAGE_DECORATIONS
				: API_MANAGE_DECORATIONS_SUB_WORLD
		const result_data = await axiosSupreme(
			'get',
			(urlRequest +=
				(gettypeContextSpace() === 'World'
					? stateObjectDecoration.id_world_scene_decoration
					: stateObjectDecoration.id_sub_world_scene_decoration) +
				'/'),
			undefined
		)
		setStateObjectDecoration(result_data)
	}

	const onChangeAnimation = (event, setFunction) => {
		setFunction(event.target.value)
		setImageAnimation(listAnimations[event.target.value].image_file)
	}

	const handleKeyPress = (event) => {
		const charCode = event.charCode || event.keyCode
		if ((charCode < 48 || charCode > 57) && charCode !== 46) {
			event.preventDefault()
		}
	}

	return (
		<form onSubmit={onSubmitLevel}>
			<div
				className='admin__container__inputsCoordenadas'
				id='admin__container__inputsCoordenadas'>
				<label
					className='admin__container__inputsCoordenadas__title'
					forhtml='admin__container__inputsCoordenadas__title'>
					{t(
						'pages.editor.components.form_decoration_scene.coordinates'
					)}
				</label>
				<div className='admin__container__inputsCoordenadas__columns'>
					<input
						maxLength='45'
						name='xPosition'
						className='admin__container__inputsCoordenadas__columns__in'
						id='xPosition'
						type='text'
						placeholder={t(
							'pages.editor.components.form_decoration_scene.x_position'
						)}
						autoComplete='off'
						value={xPosition}
						onChange={(event) =>
							onInputChangeDefault(event, setXPosition)
						}
						onKeyPress={(event) => handleKeyPress(event)}
						required></input>
					<input
						maxLength='45'
						name='yPosition'
						className='admin__container__inputsCoordenadas__columns__in'
						id='yPosition'
						type='text'
						placeholder={t(
							'pages.editor.components.form_decoration_scene.y_position'
						)}
						autoComplete='off'
						value={yPosition}
						onChange={(event) =>
							onInputChangeDefault(event, setYPosition)
						}
						onKeyPress={(event) => handleKeyPress(event)}
						required></input>
				</div>
			</div>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_decoration_scene.overlapping_and_placeholder'
					)}
				</label>
				<select
					name='is_superimposed'
					className='admin__container__inputs__in'
					id='is_superimposed'
					placeholder={t(
						'pages.editor.components.form_decoration_scene.overlapping_and_placeholder'
					)}
					autoComplete='off'
					value={isSuperimposed}
					onChange={(event) =>
						onInputChangeDefault(event, setIsSuperimposed)
					}>
					<option value='' disabled>
						{t(
							'pages.editor.components.form_decoration_scene.select_option'
						)}
					</option>
					<option key='false' value='false'>
						{t('pages.editor.components.form_decoration_scene.no')}
					</option>
					<option key='true' value='true'>
						{t('pages.editor.components.form_decoration_scene.yes')}
					</option>
				</select>
			</div>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_decoration_scene.hidden_and_placeholder'
					)}
				</label>
				<select
					name='hidden'
					className='admin__container__inputs__in'
					id='hidden'
					placeholder={t(
						'pages.editor.components.form_decoration_scene.hidden_and_placeholder'
					)}
					autoComplete='off'
					value={hidden}
					onChange={(event) =>
						onInputChangeDefault(event, setHidden)
					}>
					<option value='' disabled>
						{t(
							'pages.editor.components.form_decoration_scene.select_option'
						)}
					</option>
					<option key='false' value='false'>
						{t('pages.editor.components.form_decoration_scene.no')}
					</option>
					<option key='true' value='true'>
						{t('pages.editor.components.form_decoration_scene.yes')}
					</option>
				</select>
			</div>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_decoration_scene.animation'
					)}
				</label>
				<select
					name='animation'
					className='admin__container__inputs__in'
					id='animation'
					placeholder={t(
						'pages.editor.components.form_decoration_scene.placeholder_stars'
					)}
					autoComplete='off'
					value={animation}
					required
					onChange={(event) =>
						onChangeAnimation(event, setAnimation)
					}>
					<option value=''>
						{t(
							'pages.editor.components.form_decoration_scene.select_animation'
						)}
					</option>
					{Object.keys(listAnimations).map((key) => (
						<option key={key} value={key}>
							{listAnimations[key].name}
						</option>
					))}
				</select>
			</div>
			<div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
				{imageAnimation && (
					<img
						src={imageAnimation}
						alt={t(
							'pages.editor.components.form_decoration_scene.alt_animation'
						)}
						style={{ maxHeight: '200px' }}
						loading='lazy'
					/>
				)}
			</div>
			{stateLoading === false ? (
				<div className='admin__container__boxBtn'>
					<SaveButton></SaveButton>
				</div>
			) : (
				<LoadingIcon />
			)}
		</form>
	)
}
