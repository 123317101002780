import { useContext, useEffect, useRef, useState } from 'react'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import { API_MANAGE_SOUNDS } from '../../../../utils/constantsAdmin'
import {
	onInputChangeDefault,
	onInputChangeSound,
	processErrors,
} from '../../../../utils/general_functions_forms'
import SaveButton from '../../SaveButton'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const FormSounds = ({
	stateObjectSound,
	setStateObjectSound,
	setRefreshSounds,
}) => {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [name, setName] = useState('')
	const audioFile = useRef(null)

	// ERROR FOR FORM
	const [errorAudio, setErrorAudio] = useState('')

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		audioFile.current.value = null
	}

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// SET SOUND WHEN UPDATE
	useEffect(() => {
		if (stateObjectSound !== undefined) {
			setName(stateObjectSound.name)
		} else {
			clearFields()
		}
	}, [stateObjectSound])

	// SUBMIT FORM ANIMATION
	const onSubmitSound = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('name', event.target.elements.name.value)
		if (event.target.elements.audio_file.value) {
			formData.append(
				'audio_file',
				event.target.elements.audio_file.files[0]
			)
		}
		if (stateObjectSound === undefined) {
			// SAVE
			manageSound(API_MANAGE_SOUNDS, formData, 'add')
		} else {
			// UPDATE
			manageSound(
				API_MANAGE_SOUNDS + stateObjectSound.id_sound + '/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE SOUND
	const manageSound = async (url, obj, action) => {
		try {
			// PROCESS DATA
			let result_data =
				action === 'add'
					? await axiosSupreme('post', url, obj)
					: await axiosSupreme('patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			if (action === 'add') setRefreshSounds((prev) => !prev)
			if (action === 'update') setStateObjectSound(result_data)
			setStateLoading(false)
			openModal(false)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
	}

	return (
		<form onSubmit={onSubmitSound}>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t('pages.editor.components.form_sound.name')}
				</label>
				<input
					maxLength='45'
					name='name'
					className='admin__container__inputs__in'
					id='name'
					type='text'
					placeholder={t(
						'pages.editor.components.form_sound.name_placeholder'
					)}
					autoComplete='off'
					value={name}
					onChange={(event) => onInputChangeDefault(event, setName)}
					required></input>
			</div>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t('pages.editor.components.form_sound.sound_file')}
				</label>
				{stateObjectSound !== undefined ? (
					<>
						{stateObjectSound.audio_file !== null ? (
							<label htmlFor=''>
								{t('common.current_file')}
								<a
									href={stateObjectSound.audio_file}
									target='_blank'
									rel='noopener noreferrer'>
									{t('common.open')}
								</a>
							</label>
						) : null}
					</>
				) : null}
				<input
					name='audio_file'
					className='admin__container__inputs__in'
					id='audio_file'
					type='file'
					ref={audioFile}
					autoComplete='off'
					onChange={(event) =>
						onInputChangeSound(event, setErrorAudio, audioFile)
					}
					accept='audio/*'
					required={stateObjectSound === undefined}></input>
				{errorAudio && <p>{errorAudio}</p>}
			</div>

			{stateLoading === false ? (
				<div className='admin__container__boxBtn'>
					<SaveButton />
				</div>
			) : (
				<LoadingIcon />
			)}
		</form>
	)
}
